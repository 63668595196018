import * as React from "react";
import { Section } from "../components/section";
import { SectionTitle } from "../components/sectionTitle";
import { Job } from "../components/job";
import { School } from "../components/education";
export function Education() {
  return (
    <Section>
      <SectionTitle title="Education" />
      <School
        logo="img/tedu/logo.png"
        name="TED University"
        degree="Bachelor's degree, Computer Engineer"
        duration="2014 - 2018"
        description="Full Scholarship"
      />
      <School
        logo="img/pwr.png"
        name="Wroclaw University of Science and Technology"
        degree="Bachelor's degree, Computer Science"
        duration="2017 Spring"
        description="Erasmus"
      />
      <School
        logo="img/tedankara.png"
        name="TED Ankara Collage Private High School"
        degree="High School"
        duration="2010-2014"
        description="Full Scholarship"
      />
    </Section>
  );
}
