import * as React from "react";

export function App(props: {
  id?: string;
  name: string;
  logo: string;
  company: string;
  tags?: string[];
  year: string;
  description: string;
  screenshots: string[];
  screenshotHeight?: number;
}) {
  return (
    <div id={props.id} style={{ paddingBottom: 14, paddingTop: 14 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <img
          style={{
            width: 48,
            height: 48,
            borderRadius: 12,
          }}
          src={props.logo}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 24,
            flex: 1,
          }}
        >
          <span
            style={{
              fontWeight: 600,
              textAlign: "left",
              fontSize: 18,
            }}
          >
            {props.name}
          </span>
          <span
            style={{
              fontWeight: 400,
              textAlign: "left",
              fontSize: 16,
              marginTop: 2,
            }}
          >
            {props.company}
          </span>
          <span
            style={{
              fontWeight: 400,
              textAlign: "left",
              fontSize: 14,
              marginTop: 4,
              opacity: 0.5,
            }}
          >
            {props.year}
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "var(--item-padding)",
          marginTop: 12,
        }}
      >
        {props.tags && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {props.tags &&
              props.tags.map((tag, i) => (
                <div
                  key={i}
                  style={{
                    backgroundColor: "#1e1e1e",
                    marginRight: 8,
                    marginBottom: 6,
                    padding: 6,
                    paddingLeft: 12,
                    paddingRight: 12,
                    fontSize: 12,
                    borderRadius: 24,
                    fontWeight: 600,
                    opacity: 0.9,
                  }}
                >
                  {tag}
                </div>
              ))}
          </div>
        )}
        <span
          style={{
            fontWeight: 400,
            textAlign: "left",
            fontSize: 15,
            marginTop: 4,
            letterSpacing: 0,
            opacity: 0.8,
            //   marginLeft: -64,
          }}
        >
          {props.description}
        </span>
        <div
          style={{
            display: "flex",
            scrollBehavior: "smooth",
            overflow: "hidden",
            height: props.screenshotHeight || 400,
            position: "relative",
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              overflow: "scroll",
              position: "absolute",
              width: "100%",
              maxWidth: "100%",
            }}
          >
            {props.screenshots.map((ss) => {
              return (
                <img
                  src={ss}
                  style={{
                    display: "inline-block",
                    height: props.screenshotHeight || 400,
                    //     aspectRatio: 16 / 9 + "",
                    marginRight: 8,
                    borderRadius: 12,
                  }}
                />
              );
            })}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            //  width: "calc(100% + 64px)",
            backgroundColor: "#666666",
            height: 1,
            marginTop: 6,
            //   marginLeft: -64,
          }}
        />
      </div>
    </div>
  );
}
