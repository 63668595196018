import * as React from "react";
export function Job(props: {
  logo: string;
  title: string;
  company: string;
  duration: string;
  description: string;
  tags?: string[];
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 14 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          style={{
            width: 48,
            height: 48,
            borderRadius: "50%",
            marginBottom: 24,
          }}
          src={props.logo}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 24,
            flex: 1,
          }}
        >
          <span
            style={{
              fontWeight: 600,
              textAlign: "left",
              fontSize: 18,
            }}
          >
            {props.title}
          </span>
          <span
            style={{
              fontWeight: 400,
              textAlign: "left",
              fontSize: 16,
              marginTop: 2,
            }}
          >
            {props.company}
          </span>
          <span
            style={{
              fontWeight: 400,
              textAlign: "left",
              fontSize: 14,
              marginTop: 4,
              opacity: 0.5,
            }}
          >
            {props.duration}
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 14,
          marginLeft: "var(--item-padding)",
        }}
      >
        <span
          style={{
            fontWeight: 400,
            textAlign: "left",
            fontSize: 15,
            marginTop: 12,
            letterSpacing: 0,
            opacity: 0.8,
          }}
          dangerouslySetInnerHTML={{ __html: props.description }}
        />

        {props.tags && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: 12,
            }}
          >
            {props.tags &&
              props.tags.map((tag, i) => (
                <div
                  key={i}
                  style={{
                    backgroundColor: "#1e1e1e",
                    marginRight: 8,
                    marginBottom: 6,
                    padding: 6,
                    paddingLeft: 12,
                    paddingRight: 12,
                    fontSize: 12,
                    borderRadius: 24,
                    fontWeight: 600,
                    opacity: 0.9,
                  }}
                >
                  {tag}
                </div>
              ))}
          </div>
        )}

        <div
          style={{
            width: "100%",
            //  width: "calc(100% + 64px)",
            backgroundColor: "#666666",
            height: 1,
            marginTop: 6,
            //   marginLeft: -64,
          }}
        />
      </div>
    </div>
  );
}
