import * as React from "react"
import { Job } from "../components/job"
import { JobMultiple } from "../components/jobMultiple"
import { Section } from "../components/section"
import { SectionTitle } from "../components/sectionTitle"

export function Experience() {
  return (
    <Section>
      <SectionTitle title="Experience" />
      <JobMultiple
        logo="img/twilio.png"
        positions={[
          {
            title: "Staff Software Engineer",
            duration: "May 2023 - Now",
          },
          {
            title: "Technical Lead",
            duration: "Jan 2023 - May 2023",
            description:
              "I had the opportunity to be the Tech Lead of my team for 3 months. As of April 2023, our org decided to eliminate Tech Lead role from all teams so that engineers can focus on building awesome products.",
          },
          {
            title: "Staff Software Engineer",
            duration: "Mar 2022 - Jan 2023",
          },
          {
            title: "Senior Software Engineer",
            duration: "Mar 2021 - Mar 2022",
          },
          {
            title: "Software Engineer",
            duration: "July 2020 - Mar 2021",
            description:
              "Working on mobile app development with React Native in Messaging Mobile Apps team.",
          },
        ]}
        company="Twilio"
        duration="July 2020 - Now"
        description="Working on mobile app development with React Native in Messaging Mobile Apps team."
        tags={[
          "React Native",
          "Android",
          "iOS",
          "TypeScript",
          "Java",
          "React",
          "react-native-web",
        ]}
      />
      <Job
        logo="img/otsimo.jpeg"
        title="Software Engineer"
        company="Otsimo"
        duration="July 2017 - July 2020"
        description="Front-end and Mobile Developer. I took part in development of <a href='#otsimo'>Otsimo Special Education</a> and <a href='#speech'>Otsimo Speech Therapy</a>"
        tags={[
          "React.js",
          "React Native",
          "Java",
          "Android",
          "Swift",
          "iOS",
          "Web",
          "TypeScript",
          "Material-UI",
          "Storybook",
          "react-native-web",
        ]}
      />
      <Job
        logo="img/teyit.png"
        title="Software Engineer"
        company="teyit.org"
        duration="Feb 2018 - July 2020"
        description="teyit.org is an independent fact-checking organization based in Turkey. I improved and developed new features for <a href='#dubito'>Dubito</a>. I was also responsible for the maintanence of the whole tecnological infrastructure of the company."
        tags={[
          "React.js",
          "Material-UI",
          "TypeScript",
          "Node.js",
          "WordPress",
          "AWS",
          "Docker",
          "Chrome Extension",
        ]}
      />
      <Job
        logo="img/joyin.png"
        title="Co-Founder"
        company="Joyin"
        duration="Apr 2016 - Dec 2018"
        description="<a href='#joyin'>joyin</a> is a social platform that lets users to discover and create events. I was responsible for developing Android and iOS mobile apps and React website of the application."
        tags={["Android", "Java", "iOS", "Swift", "React.js", "TypeScript"]}
      />
    </Section>
  )
}
