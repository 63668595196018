import * as React from "react";

export function SectionTitle(props: { title: string }) {
  return (
    <span
      style={{
        fontWeight: 400,
        fontSize: 22,
        textAlign: "left",
        marginBottom: 28,
      }}
    >
      {props.title}
    </span>
  );
}
