import React from "react"
import App from "../App"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Can Çitoğlu - Software Engineer</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content="Can Çitoğlu - Software Engineer" />
        <meta
          name="description"
          content="Mobile, iOS, Android, React Native, React.js, Java, Swift, Typescript"
        />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link rel="manifest" href="manifest.json" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <App />
    </div>
  )
}
