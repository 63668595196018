import * as React from "react";

export function Section(props: { children: any }) {
  return (
    <div
      style={{
        margin: 16,
        backgroundColor: "#3E3E3E",
        flex: 1,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        borderRadius: 4,
      }}
    >
      {props.children}
    </div>
  );
}
