import * as React from "react"
import { SectionTitle } from "../components/sectionTitle"
import { Section } from "../components/section"
import { App } from "../components/app"

export function Works() {
  return (
    <Section>
      <SectionTitle title="Works" />
      <App
        id="frontline"
        name="Twilio Frontline"
        year="2020 - Now"
        logo={"img/frontline/logo.png"}
        company="Twilio"
        description="Twilio Frontline, the programmable sales engagement solution, is a mobile-first application for salespeople to build personalized digital relationships with clients over channels like SMS, WhatsApp, Voice, and Chat while maintaining security and compliance."
        tags={[
          "Android",
          "iOS",
          "Web",
          "React Native",
          "React",
          "Java",
          "Swift",
        ]}
        screenshots={["img/frontline/s1.png", "img/frontline/s2.png"]}
      />
      <App
        id="speech"
        name="Otsimo | Speech Therapy"
        year="2019 - 2020"
        logo={"img/speech/logo.png"}
        company="Otsimo"
        description="Otsimo | Speech Therapy Pronunciation Articulation is a voice-controlled and video modeling-based language and speech therapy app. Individuals with speech delays, stuttering, apraxia, non-verbal autism, articulation problems, aphasia, and late talkers can easily use the app for their language and speech development."
        tags={["Android", "iOS", "React Native", "Java", "Swift"]}
        screenshots={[
          "img/speech/s1.png",
          "img/speech/s2.png",
          "img/speech/s3.png",
          "img/speech/s4.png",
        ]}
      />
      <App
        id="otsimo"
        name="Otsimo | Special Education"
        logo={"img/otsimo/logo.png"}
        company="Otsimo"
        year="2017 - 2020"
        description="Otsimo is a certified and awarded educational game application developed for individuals diagnosed with learning disorders, attention deficit, autism, down syndrome, aspergers, and other special needs."
        tags={["Android", "iOS", "React Native", "Java", "Swift"]}
        screenshots={[
          "img/otsimo/s1.png",
          "img/otsimo/s2.png",
          "img/otsimo/s3.png",
          "img/otsimo/s4.png",
        ]}
      />
      <App
        id="dubito"
        name="Dubito"
        logo={"img/dubito/logo.png"}
        company="teyit.org"
        year="2018 - 2020"
        description="Dubito is a fact-checking helper tool. It automatically collects user reports from Messenger, Twitter Direct Message, Twitter Mentions so that Engagement Editor can assign them to cases. Archieves links that are attached to cases in case of change or removal. Shows URL's engagement info on Facebook for prioritization. Dubito also has a chrome extension to see URLs' virality rate on Facebook."
        tags={["Web", "Laravel", "React", "Node.js", "Chrome Extension"]}
        screenshots={["img/dubito/s1.png", "img/dubito/s2.jpg"]}
      />
      <App
        id="joyin"
        name="joyin"
        logo={"img/joyin.png"}
        company="joyin"
        year="2016 - 2017"
        description="Meeting new people, socializing easily, hearing about nearby events before anyone else does, and never getting stuck on 'what should I do today?' are some of many privileges joyin users benefit from. You can discover nearby events or create your own events and find your attenders, easy as a breeze. Going somewhere and checking your phone is no more! Check your phone and go somewhere, with joyin!"
        tags={["Android", "iOS", "Java", "Swift", "React.js"]}
        screenshots={[
          "img/joyin/s1.png",
          "img/joyin/s2.png",
          "img/joyin/s3.png",
          "img/joyin/s4.png",
        ]}
      />
      <App
        id="teduapp"
        name="TED Üniversitesi"
        logo={"img/tedu/logo.png"}
        company="TED University"
        year="2016 - 2017"
        description="Offical Android app of TED University. It lets users follow news and announcements of the university and let students access Moodle content from their mobile devices."
        tags={["Android", "Java", "PHP", "Moodle"]}
        screenshots={[
          "img/tedu/s1.png",
          "img/tedu/s2.png",
          "img/tedu/s3.png",
          "img/tedu/s4.png",
        ]}
      />
      <App
        id="tutor"
        name="TEDU Tutor"
        logo={"img/tutor/logo.png"}
        company="Personal"
        year="2016"
        description="TED University has a peer tutoring system for their students. This app is designed for  students so that they can see when the tutor of a desired course is available. Users can see the weekly schedule and next available hour of TEDU Tutors"
        tags={["Android", "Java"]}
        screenshots={["img/tutor/s1.png", "img/tutor/s2.png"]}
      />
      <App
        id="fizikist"
        name="Fizikist"
        logo={"img/fizikist/logo.png"}
        company="Fizikist"
        year="2014 - 2015"
        description="The aim of the Fizikist is to make science enjoyable, to explain in a language that everyone can understand, to instill more people into science, to ensure that people sit in their places not only to follow developments, but to contribute to science, and to bring scientific developments from the most accurate sources to people."
        tags={["Android", "Java", "PHP"]}
        screenshots={[
          "img/fizikist/s1.png",
          "img/fizikist/s2.png",
          "img/fizikist/s3.png",
          "img/fizikist/s4.png",
        ]}
      />
    </Section>
  )
}
