import * as React from "react"
export function JobMultiple(props: {
  logo: string
  company: string
  positions: { title: string; duration: string; description?: string }[]
  duration: string
  description: string
  tags?: string[]
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 14 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          style={{
            width: 48,
            height: 48,
            borderRadius: "50%",
            marginBottom: 12,
            backgroundColor: "white",
          }}
          src={props.logo}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 24,
            flex: 1,
          }}
        >
          <span
            style={{
              fontWeight: 600,
              textAlign: "left",
              fontSize: 18,
            }}
          >
            {props.company}
          </span>
          <span
            style={{
              fontWeight: 400,
              textAlign: "left",
              fontSize: 14,
              marginTop: 2,
            }}
          >
            {props.duration}
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 14,
        }}
      >
        {props.positions.map((pos, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 4,
                marginRight: 44,
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <div
                style={{
                  marginTop: 6,
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: "white",
                }}
              />
              {index < props.positions.length - 1 && (
                <div
                  style={{
                    marginTop: 10,
                    width: 2,
                    flex: 1,
                    backgroundColor: "white",
                  }}
                />
              )}
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    textAlign: "left",
                    fontSize: 16,
                  }}
                >
                  {pos.title}
                </span>
                <span
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                  }}
                >
                  {pos.duration}
                </span>
                {pos.description && (
                  <span
                    style={{
                      fontWeight: 400,
                      textAlign: "left",
                      fontSize: 15,
                      marginTop: 12,
                      letterSpacing: 0,
                      opacity: 0.8,
                    }}
                    dangerouslySetInnerHTML={{ __html: pos.description }}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
        {props.tags && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: 12,
              marginLeft: "var(--item-padding)",
            }}
          >
            {props.tags &&
              props.tags.map((tag, i) => (
                <div
                  key={i}
                  style={{
                    backgroundColor: "#1e1e1e",
                    marginRight: 8,
                    marginBottom: 6,
                    padding: 6,
                    paddingLeft: 12,
                    paddingRight: 12,
                    fontSize: 12,
                    borderRadius: 24,
                    fontWeight: 600,
                    opacity: 0.9,
                  }}
                >
                  {tag}
                </div>
              ))}
          </div>
        )}

        <div
          style={{
            //  width: "100%",
            //  width: "calc(100% + 64px)",
            backgroundColor: "#666666",
            height: 1,
            marginTop: 6,
            marginLeft: "var(--item-padding)",
            //   marginLeft: -64,
          }}
        />
      </div>
    </div>
  )
}
