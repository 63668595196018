import * as React from "react";
import { Job } from "./job";
export function School(props: {
  logo: string;
  name: string;
  degree: string;
  duration: string;
  description?: string;
}) {
  return (
    <Job
      logo={props.logo}
      title={props.name}
      company={props.degree}
      duration={props.duration}
      description={props.description || ""}
    />
  );
}
