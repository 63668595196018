import React from "react"
import "./App.css"
import { Experience } from "./sections/experience"
import { Works } from "./sections/works"
import { Education } from "./sections/education"
import { LinkedIn } from "./icons/linkedin"
import { GitHub } from "./icons/github"
import { GitLab } from "./icons/gitlab"
import { AppStore } from "./icons/appstore"
import { PlayStore } from "./icons/playstore"

function App() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#191919",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 72,
        }}
      >
        <div style={{ fontWeight: 800, fontSize: 32 }}>Can Çitoğlu</div>
        <div style={{ fontWeight: 400, marginTop: 8, fontSize: 20 }}>
          Software Engineer
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 12,
            marginTop: 24,
          }}
        >
          <AppStore link="https://apps.apple.com/in/developer/can-citoglu/id1649041299" />
          <PlayStore link="https://play.google.com/store/apps/developer?id=Can+Citoglu" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 12,
            marginTop: 12,
          }}
        >
          <GitHub link="https://github.com/cancit" />
          <GitLab link="https://gitlab.com/codescu" />
          <LinkedIn link="https://www.linkedin.com/in/can-citoglu/" />
        </div>
        <div
          style={{
            marginTop: 32,
            width: "100%",
            maxWidth: 960,
            alignItems: "stretch",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Experience />
          <Education />
          <Works />
        </div>
      </div>
    </div>
  )
}
export default App
